import http from "../../utils/http-service";
import { API_BASE_URL, fetchBgDataUrl } from "../../utils/urls";
import {
  FETCH_HISTORY_START,
  FETCH_HISTORY_FAILED,
  FETCH_HISTORY_SUCCESS,
} from "../actionTypes/history";
import {encrypt, decrypt} from "../../utils/encryption";
import moment from "moment";
export const fetchHistoryData = (data) => async(dispatch) => {
  dispatch({
    type: FETCH_HISTORY_START,
  });

  let type = 4;
try {

    let historyTime;
    let res;
    let dayDif;
    if(data.time){
      historyTime = data.time;
      dayDif = data.time;
      historyTime = await encrypt(historyTime);


      type = JSON.stringify(type);
      type = await encrypt(type);

     res = await http.get(`${API_BASE_URL}${fetchBgDataUrl}${type}?time=${historyTime}`);
    } else if(data.start_date) {

      let history_start = data.start_date;
      // bg_start = encrypt(bg_start);
      let history_end = data.end_date;
      // bg_end = encrypt(bg_end);
       dayDif = moment.duration(history_end.diff(history_start)).asDays();

      // type = JSON.stringify(type);
      // type = encrypt(type);

     res = await http.get(`${API_BASE_URL}${fetchBgDataUrl}${type}?start_date=${history_start}&end_date=${history_end}`,);
}
let gift = res.data.data;

let his = await dispatch(fetchCgmDataForHistory(gift.overlay, dayDif));

// gift = decrypt(gift);
// gift = JSON.parse(gift);

  // updateUnit(unit, res.data.data)
  // dispatch({ type: FETCH_HISTORY_SUCCESS, payLoad: gift });

  return his;
} catch (error) {

  dispatch({
    type: FETCH_HISTORY_FAILED,
  });
}
};



// const findMinMax=(arr,key)=> {
// const datas = arr.map((node) => node[key]);
// return {
//   min: Math.min(...datas),
//   max: Math.max(...datas),
// }
// }



const fetchCgmDataForHistory = (response, daysDif) => async(dispatch) => {
let dDifference = daysDif.replace(/[^A-Z\d\s]/g, "");
// console.log("dDifference", dDifference);


    // let cgm_start = data.timeFrom;
    let cgm_start = findStartDate(daysDif);


    const res = response;
    let weeksArr=[];



        let data = response;
        let firstWeekStartDate = moment(cgm_start);

        // let numberOfWeeks = Math.ceil(daysDif / 1);
        let numberOfWeeks = dDifference;

        let weekStartDate="";
        let weekEndDate="";
        for(let i=0; i<numberOfWeeks; i++){
          if(i === 0){
            weekStartDate= firstWeekStartDate;
            weekEndDate = moment(firstWeekStartDate).add(24, 'hours');
          }
          let week=[];
          if (data && data.length > 0) {
            let weekSD = weekStartDate;
            let weekED = weekEndDate;

            data.forEach((day, index)=> {
            let p1 = moment(weekSD);

            let p2 = moment(day.time);

            let p3 = moment(weekED);


            if (p2.isAfter(p1) && p2.isBefore(p3)){
              // // // // console.log("READING TIME", p2);
              let timeUTC = day.time;
              // // // console.log("day.time", day.time);
              // let converted = moment(timeUTC).utc();
              // // // // console.log("CHECK", moment(converted).tz.guess());

              // const gue =  moment.tz(timeUTC, "UTC");
              const gue =  moment(timeUTC);
              // // // console.log("gue", gue);
              let conTime = moment(gue._d).format("HH:mm");
              // // // console.log("conTime",conTime);

              let obj = {readingType: day.readingType, time: day.time, glucose: day.value, takenTime:day.takenTime, userPId: day.userPId, _id: day._id}
              // console.log("OBJECT :", obj);
              week.push(obj);
            }
          })
        } else {
        }
          let obj = {weekStartDate:new Date(weekStartDate).toString(),
            readings:[week]}
          weeksArr.push(obj);
          // weekStartDate= moment(weekEndDate).add(1, 'days');
          // weekEndDate = moment(weekEndDate).add(7, 'days');
          weekStartDate= moment(weekEndDate)
          weekEndDate = moment(weekEndDate).add(24, 'hours');
        };




    // let gift = res.data;

  dispatch({ type: FETCH_HISTORY_SUCCESS, payLoad: weeksArr });
  // // console.log("WEEK ARRAY", weeksArr);
  return weeksArr;

};


const findStartDate = (filter) => {


    if(filter === "30 days") {
      let start = moment().subtract(30, 'days');


      return new Date(start._d);

    }else if(filter==="7 days") {

      let start = moment().subtract(7, 'days');


      return new Date(start._d);


    } else if (filter === "14 days") {


      let start = moment().subtract(14, 'days');

      return new Date(start._d);

    } else if (filter === "90 days") {

      let start = moment().subtract(90, 'days');


      return new Date(start._d);

    } else if(filter === "24 hours"){

      let start = moment().add(-24, 'hours');


      return new Date(start._d);

    }
    else if(filter === "12 hours"){
      let start = moment().add(-12, 'hours');


      return new Date(start._d);
    }
    else if(filter === "6hours"){
      let start = moment().add(-6, 'hours');


      return new Date(start._d);
    } else if (filter === "today") {

      return new Date();

    } else{

    }

  }


//   const makeAGPReadings= (data)=>{
//   let eachHourData=[];
//   let timeSlots=["00", "01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"];
//
//     for(let i =0;i<24; i++){
//       let obj = data.filter((dayInner, index)=>{
//       return  parseInt(moment(dayInner.time).format("HH")) === parseInt(timeSlots[i])
//
//     });
//       obj.sort((a, b)=>{return a.value-b.value});
//       eachHourData.push(obj);
//     }
//   return eachHourData;
// }
