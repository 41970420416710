import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, NavLink, Link , useNavigate} from "react-router-dom";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Components/Common/Loading"
import moment from "moment";
import { default as Titles } from "../utils/document-titles";
import { Menu } from "antd";

import {
  HomeOutlined,
  FileOutlined,
  TeamOutlined,
  ApiOutlined,
  UserOutlined,
  MessageOutlined,
  MedicineBoxOutlined,
  AlertOutlined,
  MonitorOutlined,
  BranchesOutlined,
  DesktopOutlined,
  FileImageOutlined,
  FileSearchOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import {saveModus, fetchClinicians, addClinicID, getClinicians, checkClinicID,addSubjects,stopClinicalTrial} from "../redux/actions/admin/admin";
import {fetchUser,updateUserRole} from "../redux/actions/user";
import {encrypt, decrypt} from "../utils/encryption";

import { Tabs } from "antd";




export default function SubAdmins() {
  document.title = Titles.clinicalDashboard;

  const dispatch = useDispatch();
  const history = useNavigate();
  const {cliniciansData} = useSelector((state)=>{return state.admin});
  const { userDetails } = useSelector((state) => {return state.user});
  const [toDelete, setToDelete] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [cliId, setCliId] = useState("");
  const [filileUploadingStart, setFilileUploadingStart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);



  // document.title = DocumentTitles.history;
  const [selectedApp, setSelectedApp] = useState("");
  const [management, setManagement] = useState(false);
  const [loading,setLoading]=useState(false);
  const [addModal, setAddModal] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [idAvail, setIdAvail] = useState(null);
  const [showAddBtn, setShowAddBtn] = useState(false);

  const [clinicId, setClinicId]= useState("");


  useEffect(() => {
    if(userDetails && userDetails.user){

    } else {
      if(localStorage.getItem("Apple")){
        let user = localStorage.getItem("Apple");
        // user = decrypt(user);
        // user = JSON.parse(user);
        dispatch(updateUserRole(user));
      } else {
        dispatch(fetchUser());

      }
    }
fetchingClinicians();
},[]);

useEffect(() => {
},[idAvail]);

const fetchClinicsEntities = (shrtId) => {
 history(`/admin/Clinicians/:${shrtId}`);


  // try{
  //  // const ress= await dispatch(fetchEngineerData(id, email));
  //
  //  const ress= await dispatch(getClinicians(2,"modus"));
  //  if(ress.status=== 200){
  //
  //   setLoading(false);
  //   history(`/admin/Clinicians`);
  //
  //
  //   // props.onClick(true);
  //
  //
  //  }
  //
  // }catch(error){
  //   setLoading(false);
  //
  // }

  };

const fetchingClinicians = async () => {
const result = await dispatch(fetchClinicians())
  };


const toAddClinicID= async(e)=>{
  e.preventDefault();
  let data = {
  "startDate": new Date(startDate),
  // "endDate": endDate,
  "shortId": clinicId
}
if(idAvail){
  let ret = await dispatch(addClinicID(data))
  setAddModal(false);
  dispatch(fetchClinicians());
}
}
const checkCliID = async(val) => {
  if(val!=="" && val.length>2 && val !==" "){
  let res = await dispatch(checkClinicID(val.trim()));

  if(res.status && res.status === 200) {
    setIdAvail(true);
    setShowAddBtn(true);
  } else{
    setIdAvail(false);
    setShowAddBtn(false);


  }

} else if(val.length<3){
  setIdAvail(null);
  setShowAddBtn(false);
}
}
// const implementChecks = (val) => {
//
//     setIdAvail(null);
//     setShowAddBtn(false);
//
// }
const csvReader = (e) =>{
  const [file] = e.target.files;

  const reader = new FileReader();

  // const data = reader.readAsDataURL(file);

  reader.onload = () => {
    // @ts-ignore
    // document.getElementById('out').innerHTML = reader.result;
    let cs = reader.result.split("\n");
    let data = [];
    for(let  i = 0; i < (cs.length-1); i++){
      let obj = {

      };
      let inst = cs[i].split(",");
      obj = {
        subjectId: inst[0],
        password: inst[1].replace("\r", "")
      }
      data.push(obj);
    }
    // cs.forEach((item) => {
    //   let obj = {
    //
    //   };
    //   let inst = item.split(",");
    //   obj = {
    //     subjectId: inst[0],
    //     password: inst[1]
    //   }
    //   data.push(obj);
    // });

addingSubjects(data);
  };
  // start reading the file. When it is done, calls the onload event defined above.
  // @ts-ignore
  reader.readAsBinaryString(document.getElementById('csvInput').files[0]);
}

const addingSubjects=async(data)=>{
  setFilileUploadingStart(true);

  let res = await dispatch(addSubjects(data));
if(res === true){
  fetchingClinicians();
}
setFilileUploadingStart(false);

}
const stoppingClinicalTrials = async()=>{
  let id = toDelete;
  let result = await dispatch(stopClinicalTrial(id));

  if(result === true){
    fetchingClinicians();
  }
  setDeleteModal(false);



}

if (userDetails &&
   userDetails.user && (userDetails.user.role.includes(6)===true || userDetails.user.role.includes(1)===true || userDetails.user.role.includes(0)===true)) {

return (
  <div className="container-fluid patient-list h-100">
      {/*<div className="container bg-white rounded my-5 p-3">*/}
      {/* <form onSubmit={handleSubmit}> */}
          <div className="row p-2 px-3 h-100">
              <div className="col-12">
                  <div className="row justify-content-between pl-3 h-100">

                      <div className="col-12 col-md-6">
                        <h1 className="pt-3 pb-3 font-weight-bold chart-heading">Clinical Dashboard</h1>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <button className="btn btn-primary mt-3" onClick={()=>{setAddModal(true)}}>Add Clinic ID</button>
                        <label htmlFor="csvInput" className="btn btn-primary mt-3 mb-0 ml-2"><i className="fa fa-upload mr-2" aria-hidden="true"></i>Subjects CSV</label>
                        <input
                          accept=".csv"
                          value=""
                          id="csvInput"
                          hidden
                          onChange={(e) => {
                            csvReader(e);
                          }}
                          type="file"
                        />

                      </div>

                      <div className="col-12">
                      {loading?
                      <Loading/>
                      :
                      <Spin spinning={filileUploadingStart}>
                      <table className="table table-hover" id="task-table">
                        <thead>
                        <tr>
                          <th>Date</th>
                          <th>Clinic Test ID</th>
                          <th>No of Subjects</th>
                          <th className="text-left">Actions</th>
                        </tr>
                        </thead>

                        <tbody>
                        { cliniciansData !== null && cliniciansData.clinics && cliniciansData.clinics.length > 0 && cliniciansData.clinics.map((row, index)=>(

                        <tr key={index+1}>
                          <td>{moment(row.startDate).format("DD-MM-YYYY")}</td>
                          <td>{row.shortId}</td>
                          <td>{Array.isArray(row.subjects) === true? row.subjects.length:null}</td>
                          <td className='text-left'>
                            <button
                                type="button"
                                className=""
                                title="View"
                                onClick={(event)=>{
                                fetchClinicsEntities(row.shortId);
                                }}
                            >
                              <span className="">
                              <i className="fa fa-eye" aria-hidden="true"></i>
                              </span>
                            </button>
                            {row.status === 1?
                            <button
                                type="button"
                                className="ml-2"
                                title="Stop Clinical Trial"
                                onClick={(event)=>{
                                  setToDelete(row.shortId);
                                  setDeleteModal(true);
                                  setCliId(row.shortId);
                                }}
                            >
                              <span className="">
                              <i className="fa fa-times" aria-hidden="true"></i>
                              </span>
                            </button>:null}
                          </td>
                        </tr>

                      ))}

                        </tbody>

                      </table>
                      </Spin>
                    }
                      </div>
                      {/* <div className="col-12">
                        <table className="table session-table" id="tab1">
                          <thead>
                           <tr>

                            <th>firstPointSamplingDelay_ms</th>
                            <th>flags</th>
                            <th>intermissionTime_ms</th>
                            <th>maxNumOfPoints</th>
                            <th>measurementPeriod_s</th>
                            <th>NumberOfMeasurements</th>
                            <th>ovsRate</th>
                            <th>pointSamplingPeriod_ms</th>
                            <th>safeguardVoltage_mV</th>
                            <th>scanCase</th>
                            <th>scanType</th>
                            <th>wecom_mV</th>
                            <th>measurementData</th>
                          </tr>
                          </thead>
                        {eData && eData.length>0 && eData.map((session,index)=>(

                          <Table row={session} count={index}/>

                        ))}
                        </table>

                      </div> */}




                      <div
                          className={`modal big_modal_dialog ${
                              addModal? "d-block modal-fading" : " d-none"
                          }`}
                          tabIndex="-1"
                          data-backdrop="static"
                          data-keyboard="false"

                          role="dialog"

                          aria-hidden="true"
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="addHospitalModalTitle">
                                Add Clinic ID
                              </h5>
                              <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  onClick={()=>setAddModal(false)}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <form className="pl-3 pr-3">
                              <div className="container my-4 ">

                                <div className="row justify-content-center align-items-center mb-3 text-white">

                                  <div className="col-12 registration-input-labels"><p className="text-black text-center">Start Date</p></div>
                                  <div className="col-12 text-center">
                                    <input
                                      value={startDate}
                                      onChange={(e) => setStartDate(e.target.value)}
                                      type="date"
                                      className="registration-datePicker"
                                      required
                                      style={{color:"black"}}
                                      min={new Date().toISOString().split('T')[0]}
                                    />
                                  </div>
                                </div>

                                <Spin spinning={isLoading}>
                                <div className="row justify-content-center align-items-center mb-3 text-white">

                                  <div className="col-12 registration-input-labels"><p className="text-black text-center">Clinic ID</p></div>
                                  <div className="col-12 text-center">


                                    <input type="text" id="clinicID" name="clinicID" placeholder="Enter Clinic ID"
                                      pattern="{3,50}" required title="Clinic ID should be between 3 and 50 characters long"
                                      value={clinicId}
                                      onChange={(e) => {
                                        setClinicId(e.target.value);
                                        checkCliID(e.target.value)}}
                                      type="text"



                                      required
                                      style={{color:"black"}}
                                       />
                                      <div className="invalid-feedback">
                                        Please enter a valid Clinic ID (between 3 and 50 characters).
                                      </div>

                                  </div>
                                  <div className="col-12 text-center">
                                    {idAvail===true?<p className="text-success">Available</p>:idAvail === false ?<p className="text-danger">Not Available</p>:null}
                                  </div>

                                </div>
                                </Spin>


                              </div>
                              <div className="modal-footer">
                              <button className="btn btn-primary" disabled={showAddBtn ? false : true} onClick={(e)=>{toAddClinicID(e)}}>Add Clinic ID </button>

                              </div>
                            </form>
                          </div>
                        </div>
                      </div>


                  </div>

                  <div
      tabIndex="-1"
      id="myModal-delete"
      className={`modal  ${
        deleteModal ? "d-block fading" : " d-none"
      }`}
    >
      <div className="modal-dialog modal-dialog-centered modal-confirm">
        <div className="modal-content">
          <div className="modal-header">
            <div className="icon-box mr-2">
              <i className="fas fa-trash-alt text-danger"></i>
            </div>
            <h4 className="modal-heading w-100 text-black">
              Are you sure?
            </h4>
            <button
              type="button"
              className="close"
              onClick={()=>{setDeleteModal(false);setCliId("");}}
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <p>
              Do you really want to stop "{cliId}" clinical trial? This
              process cannot be undone.
            </p>
          </div>
          <div className="modal-footer justify-content-center">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={()=>{setDeleteModal(false);setCliId("");}}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {

                stoppingClinicalTrials();
              }}
            >
              Stop
            </button>
          </div>
        </div>
      </div>
    </div>

              </div>
          </div>
      {/*</div>*/}
  </div>
);
}



  return (
    <h1 className="text-center" style={{height:"85vh"}}>You Are Not Athorized</h1>

  );
}
