export const API_BASE_URL = "https://api.moduscgm.net";

// export const loginURL = "/user/login";
export const loginURL = "/user/e_login";

// export const createUserURL = "/user/signup";
export const createUserURL = "/user/e_signup";
// export const forgotPasswordURL = "/user/forgotPassword";
export const forgotPasswordURL = "/user/e_forgotPassword";

// export const getEngineeringData = "/admin/e_getEngineeringData";
export const getEngineeringData = "/admin/getEngineeringData";


// export const getSensorLogsData = "/admin/getSensorLogsByUser";
export const getSensorLogsData = "/admin/e_getSensorLogsByUser";


// export const getSessionDataUrl = "/admin/getEngineeringSessionData";
export const getSessionDataUrl = "/admin/e_getEngineeringSessionData";

// export const getMeasurementDataUrl = "/admin/getEngineeringCsvData";
// export const deleteSessionUrl = "/admin/deleteSessionData";
export const deleteSessionUrl = "/admin/e_deleteSessionData";

// export const sensorLogsUrl = "/admin/getSensorLogsBySession";
export const sensorLogsUrl = "/admin/e_getSensorLogsBySession";



// export const getMeasurementDataUrl = "/admin/getEngineeringMeasurementData";
export const getMeasurementDataUrl = "/admin/e_getEngineeringMeasurementData";

// export const getPointDataUrl = "/admin/getEngineeringPointData";
export const getPointDataUrl = "/admin/e_getEngineeringPointData";

export const getOTA = "/admin/getFirmware";
// export const getOTA = "/admin/e_getFirmware";

export const addOTA = "/admin/addFirmware";
// export const addOTA = "/admin/e_addFirmware";

export const deleteOTA = "/admin/removeFirmware";
// export const deleteOTA = "/admin/e_removeFirmware";


export const newEmailReq= "/user/updateEmailRequest";
export const verifyEmailReq = "/user/verifyUpdateEmailRequest";
export const newEmailConfirm= "/user/updateEmail";
export const newEmailConfirmReq= "/user/verifyUpdateEmailRequest";



// export const addSubjectsUrl = "/clinician/addSubjects";
export const addSubjectsUrl = "/clinician/e_addSubjects";

// export const getSubjects = "/clinician/getAllSubjects";
export const getSubjects = "/clinician/e_getAllSubjects";

// export const stopClinicalTrialUrl = "/clinician/stopClinicalTrial";
export const stopClinicalTrialUrl = "/clinician/e_stopClinicalTrial";

// export const stopSubjectFromCTUrl = "/clinician/stopClinicalTrialForSubject";
export const stopSubjectFromCTUrl = "/clinician/e_stopClinicalTrialForSubject";

// export const addSubToClinicalTrialUrl = "/clinician/addSubjectToClinicalTrial";
export const addSubToClinicalTrialUrl = "/clinician/e_addSubjectToClinicalTrial";


// export const deleteAccountURL = "/user/deleteAccount";
export const deleteAccountURL = "/user/e_deleteAccount";





// export const resetPasswordURL = "/user/resetPassword";
export const resetPasswordURL = "/user/e_resetPassword";
// export const fetchUserURL = "/user/get";
export const fetchUserURL = "/user/e_get";

export const resendVerificationEmailURL = "/user/resend-email-verification";
// export const resendVerificationEmailURL = "/user/e_resend-email-verification";
export const accountActicationUrl ="/user/e_activateAccount";



// export const fetchBgDataUrl = "/device/getData/";
export const fetchBgDataUrl = "/device/e_getData/";
// export const fetchSettingsUrl = "/settings/get";
export const fetchSettingsUrl = "/settings/e_get";


export const updateSettingsUrl = "/settings/update";
// export const updateSettingsUrl = "/settings/e_update";





// // export const sendFeedback = "/user/feedback";
// export const sendFeedback = "/user/e_feedback";
// export const getSensorDetails = "/device/getSensorDetails";
export const getSensorDetails = "/device/e_getSensorDetails";

export const carbsUrl = "/carbs/get";
export const insulinUrl = "/insulin/get";
export const activityUrl = "/activity/get";
