import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {useParams, useLocation, useNavigate } from "react-router-dom"

import {saveModus,engineerSessionsDataFailed} from "../../redux/actions/admin/admin";


import flag from "../../Assets/Navbar-Icons/country_flag.svg";
import notification from "../../Assets/Navbar-Icons/notification.svg";
import avtar from "../../Assets/Logo/avtar.png";
import { NavLink } from "react-router-dom";
import Logo from "../../Assets/Logo/GlucoRxLogo.svg";
import {logout} from "../../redux/actions/auth";
import ExportData from "../Export/ExportData";
import UserProfileModal from "./UserProfileModal";


const Navbar = () => {
  const { userDetails, selectedUser } = useSelector((state) => state.user);
  const { modus2 } = useSelector((state) => state.admin);

  const {app} = useParams();


  const location = useLocation();
  const history = useNavigate();


  const dispatch = useDispatch();
  const[detail, setDetail] = useState();
  const[topBar, setTopBar] = useState(false);
  const[activeReport, setActiveReport] = useState(false);
  const[eostre, setEostre] = useState(false);
  const[modus, setModus] = useState(false);



  // const name = {user.userDetials.length>0? user.user.user.firstName user.user.user.lastName:"Jhon Doe"}
  // const detail = () => {
  //   let data;
  //   if(props.user && props.user.length > 0) {
  //     data = props.user.user;
  //   }
  //   return data;
  // }
  useEffect(() => {

    if(userDetails && userDetails.user && userDetails.user.role.length===1 && userDetails.user.role[0]=== 4){

        setTopBar(true);
    }
    if(userDetails && userDetails.user && userDetails.user.role.length>1){
      for(let i = 0; i<userDetails.user.role.length; i++) {
        if(userDetails.user.role[i] === 4) {

          setTopBar(true);
        }
      }
    }
    }, [userDetails]);
  useEffect(() => {


    if(userDetails && userDetails.user){

      setUser(userDetails.user);
    } else {



    }
    if(location.pathname==="/Report"||
    location.pathname==="/Pattern"||
    location.pathname==="/dashboard"||
    location.pathname==="/Trend"||
    location.pathname==="/Overlay"||
    location.pathname==="/History"||
    location.pathname==="/Compare"||
    location.pathname==="/Statistics"||
    location.pathname==="/Agp" ||
    location.pathname==="e-ostre" ||
    location.pathname==="modus") {
      setActiveReport(true);
    } else {
      setActiveReport(false);

    }
    // console.log("loc", location.pathname);
  }, [userDetails,location.pathname]);


  useEffect(() => {

      // // console.log("APP", app);
      if(app !== undefined){
        let appP = app.replace(":","");
        if(appP === "eostre"){
          setEostre(true);

        } else if(appP === "modus"){
          setModus(true);

        }
        dispatch(saveModus(appP));

      } else {
      dispatch(saveModus("modus"));
      setModus(true);

    }


  },[]);

  useEffect(() => {

// // console.log("eostre", eostre);
// // console.log("modus", modus);


}, [eostre, modus]);


  const setUser = (user) => {

    setDetail(user);

  }

  const reRoute=(e)=>{
    // // console.log("EVENT", e.target.name);
    if(location.pathname.includes("/admin/EngineerData/")){

      dispatch(saveModus(e.target.name));
let cosUrl = `/admin/EngineerData/:${e.target.name}`;
// // console.log("cosUrl", cosUrl);
   history(cosUrl);
    //history('/dashboard');

    }
  }

  const handleCollapse = () => {

    var nav = document.getElementById("pills");
    // var btn = document.getElementById("navbarBtn");
    nav.classList.remove("show");
    // btn.classList.add("collapsed");
  };

  return (
    <div className="container-fluid">
    <nav className="navbar navbar-expand-lg d-flex w-100 bg-white align-items-center justify-content-md-between">

        <div className="col-3">
          <img src={Logo} className="nav-logo"></img>
        </div>

        <div className="col-md-6 col-9 d-flex align-items-center justify-content-center mt-2 mb-4">
          <div className="tab" id="pills" role="tabpanel">
          { userDetails && userDetails.user && userDetails.user.role.length===1 &&userDetails.user.role.includes(4)===true && location.pathname !== "/auth/deleteUser"&& location.pathname !== "/auth/updateEmail"
            ?
            <ul className="nav nav-tabs" role="tablist">

              <li role="presentation" className=" text-center">
                <NavLink
                  className={activeReport? "active-route tab-pane fade show ":"tab-pane fade show"}
                  to="/Report"

                >
                  <span className="icon icon-report"></span>
                  <p className="mt-1">Report</p>
                </NavLink>
              </li>
              <li role="presentation">
                <NavLink
                  // className="nav-link"
                  className={location.pathname==="/Setting"? "active-route tab-pane fade show ":"tab-pane fade show"}


                  to="/Setting"


                >
                  <span className="icon icon-setting"></span>
                  <p className="mt-1">Setting</p>
                </NavLink>
              </li>
              <li role="presentation">
                <NavLink
                  className={location.pathname==="/Support"? "active-route tab-pane fade show nav-link":"tab-pane fade show nav-link"}

                  to="/Support"
                  role="tab"

                >
                  <span className="icon icon-support"></span>
                  <p className="mt-1">Support</p>
                </NavLink>
              </li>
              {/*<li role="presentation">
              <div style={{padding:"15px 30px"}}>
                <ExportData/>
                </div>

              </li>
              */}
            </ul>
            :   null}
            {/*userDetails && userDetails.user && userDetails.user.role.includes(0)===true?
            <ul className="nav nav-tabs" role="tablist">

              <li role="presentation" className="mr-2">
                <button
                  className={eostre === true? "active-route tab-pane fade show app-btn":"tab-pane fade show app-btn"}
                  type="button"
                  name="eostre"
                  onClick={(e)=>{
                    setEostre(true);
                    setModus(false);
                    dispatch(engineerSessionsDataFailed());
                    reRoute(e);
                  }}


                >
                  Eostre

                </button>
              </li>
              <li role="presentation" className="mr-2">
                <button
                  // className="nav-link"
                  className={modus === true? "active-route tab-pane fade show app-btn":"tab-pane fade show app-btn"}
                  type="button"
                  name="modus"
                  onClick={(e)=>{
                    setEostre(false);
                    setModus(true);
                    dispatch(engineerSessionsDataFailed());
                    reRoute(e);


                  }}
                >
                Modus

                </button>
              </li>

              </ul>*/}



          </div>
        </div>
        <div className="col-3 d-none d-sm-flex justify-content-end">
          <span className="d-flex flex-row justify-content-end align-items-center">
            <img src={flag} className="mr-2" width="20px"></img>
            {/*<span className="mx-4 ">
              <img src={notification} className="" width="20px" />
            </span>
            <img
              src={avtar}
              className="figure-img img-fluid rounded-circle "
              alt=""
              width="40px"

            />*/}
          </span>

          <div className="dropdown">
            <a
              className="nav-link  d-flex justify-content-center align-items-center p-0"
              href="#"
              id="navbarDropdownMenuLink"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
            <figcaption className="figure-caption ">{detail ? detail.firstName:null}  {detail ? detail.lastName:null}</figcaption>

              <img src={avtar} width="35" height="35" className="rounded-circle" />
            </a>
            <div
              className="dropdown-menu dropdown-menu-right text-center text-white"
              style={{ backgroundColor: "#007bff" }}
              aria-labelledby="dropdownMenuButton"
            >
              <button className="bg-transparent" onClick={() => dispatch(logout())}>
                <div className="">
                  <span className="mr-lg-2">
                    <i className="fas fa-sign-out-alt"></i>
                  </span>
                  Logout
                </div>
              </button>

              <button type="button" className='bg-transparent' onClick={handleCollapse}  data-toggle="modal" id="profile-btn" data-target="#userProfileModal">
                <div className="">
                  <span className="mr-lg-2">
                    <i className="fas fa-user "></i>
                  </span>
                  Profile
                </div>
                </button>
            </div>
          </div>

        </div>

      <UserProfileModal/>

      </nav>
    </div>
  );
};

export default Navbar;
