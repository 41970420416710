import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import countryList from "../Auth/CountriesList";
import ShowToast from "../../utils/show-toast";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { register, resetEmail } from "../../redux/actions/auth";
import { decrypt } from "../../utils/encryption";
import moment from "moment";
import { fetchUser , updateUser,updateUserRole} from "../../redux/actions/user";
// import EmailUpdate from "./EmailUpdateModal";

const UserProfileModal = () => {
  const data = useSelector((state) => state.user.userDetails);
  // console.log("DATA ", data);
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState();
  const [email, setEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");

  const [password, setPassword] = useState("");

  const [address, setAddress] = useState("");
  const [town, setTown] = useState("");
  const [postCode, setPostCode] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhoneNo] = useState("");

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    // console.log("DATA UPDATED NOE IN EFFECT");
    setFirstName(
      data && data.user && data.user.firstName && data.user.firstName !== "" ? data.user.firstName : ""
    );
    setLastName(
      data && data.user && data.user.lastName && data.user.lastName !== "" ? data.user.lastName : ""
    );
    setGender(
      data && data.user && data.user.gender && data.user.gender !== "" ? data.user.gender : ""
    );
    setEmail(data && data.user && data.user.email && data.user.email !== "" ? data.user.email : "");
    setDob(
      data && data.user && data.user.dateOfBirth && data.user.dateOfBirth !== ""
        ? moment(data.user.dateOfBirth).format("YYYY-MM-DD")
        : ""
    );
    setTown(data && data.user && data.user.town && data.user.town !== "" ? data.user.town : "");
    setCountry(data && data.user && data.user.country && data.user.country !== "" ? data.user.country : "");
    setPostCode(
      data && data.user && data.user.postcode && data.user.postcode !== "" ? data.user.postcode : ""
    );
    setTown(data && data.user && data.user.town && data.user.town !== "" ? data.user.town : "");
    setAddress(data && data.user && data.user.address && data.user.address !== "" ? data.user.address : "");
    setPhoneNo(data && data.user && data.user.phone && data.user.phone !== "" ? data.user.phone : "");
  },[data]);

  const dispatch = useDispatch();


  const handleUserUpdate = async (e) => {
    e.preventDefault();

    let dateOfBirth = new Date(dob);
    dateOfBirth = moment(dateOfBirth).format("DD-MM-YYYY");
    let postcode = postCode;




    try {
      setIsLoading(true);
      let dataToBeUpdated={
        firstName,
        lastName,
        gender:JSON.parse(gender),
        "dateOfBirth":dob,
        address,
        town,
        postcode,
        phone,
      };
      // console.log("UD", dataToBeUpdated);
      let updateData = dataToBeUpdated;

      updateData=JSON.stringify(updateData);

      // updateData = encrypt(updateData).then((encrypted)=>{
      //   updateData = encrypted;
      // });

      let result = await dispatch(
        updateUser(updateData)
      );
      // dispatch(fetchUser())
      ////("Result",result);
      setIsLoading(false);
      // console.log("RESULT IN MODAL:",result);
      if(result){
        let updatedUserData = {user:{
        address:dataToBeUpdated.address,
app:data.user.app,
clinicalId:data.user.clinicalId,
country:data.user.country,
dateOfBirth:dataToBeUpdated.dateOfBirth,
diabetesType:data.user.diabetesType,
email:data.user.email,
firstName:dataToBeUpdated.firstName,
gender:dataToBeUpdated.gender,
hmac:data.user.hmac,
lastName:dataToBeUpdated.lastName,
phone:dataToBeUpdated.phone,
picture:data.user.picture,
postcode:dataToBeUpdated.postcode,
role:data.user.role,
status:data.user.status,
subjectId:data.user.subjectId,
town:data.user.town,
low:data.user.low,
high:data.user.high,
unit:data.user.unit,}};
dispatch(updateUserRole(updatedUserData));

      }
      // ShowToast(result.data.message, "success");
    } catch (err) {
      ////(err.response)

      // ShowToast(err.message, "error");
      setIsLoading(false);
    }
  };

  const resetEmailRequest=async()=>{
    try {
      setIsLoading(true);
      if(email !== newEmail){


      let result = await dispatch(
        resetEmail(newEmail)
      );


      setIsLoading(false);
      ShowToast(result.data.message, "success");
      }
    } catch (err) {
      ////(err.response)

      ShowToast(err.response.data.message, "error");
      setIsLoading(false);
    }
  }

  return (
    <div>
      <div className="text-center"></div>

      <div id="userProfileModal" className="modal fading">
        <div className="modal-dialog modal-login">
          <div className="modal-content">
            <div className="modal-header">
              <div className="avatar text-center">
                <i className="fas fa-4x fa-user text-white"></i>
              </div>
              <h4 className="modal-title">User Profile</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body overflow-auto" style={{ height: "50vh" }}>
              <form onSubmit={handleUserUpdate} >
                <div className=" ">
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label className="text-left">First Name</label>
                      <div className="form-group">

                      <input type="text" className="registration-form-control" id="firstName" name="firstName" placeholder="Enter your first name"
                        pattern=".{3,50}" required title="First Name should be between 3 and 50 characters long"
                        style={{color:"black"}}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                         type="text"
                         disabled={editMode ? false : true}
                         />
                        <div className="invalid-feedback">
                          Please enter a valid first name (between 3 and 50 characters).
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-md-6">
                      <label className="text-left">Last Name</label>

                      <div className="form-group">

                      <input disabled={editMode ? false : true} type="text" className="registration-form-control" id="lastName" name="lastName" placeholder="Enter your last name"
                        pattern=".{3,50}" required title="Last Name should be between 3 and 50 characters long"
                        style={{color:"black"}}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                         type="text"/>
                        <div className="invalid-feedback">
                          Please enter a valid first name (between 3 and 50 characters).
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="form-group col-md-6">

                      <div className="form-group">
                      <label className="text-left" for="gender">Gender</label>
                        <select disabled={editMode ? false : true} className="registration-form-control" selected={gender} id="gender" name="gender" required  value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        style={{color:"black"}}>
                          <option value="" disabled selected>Select a gender</option>
                          <option value="1">Male</option>
                          <option value="2">Female</option>
                          <option value="3">Other</option>
                          <option value="4">Prefer Not To Share</option>
                        </select>
                        <div className="invalid-feedback">
                          Please select your gender.
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-md-6">
                      <label className="text-left">Date of Birth</label>
                      <input
                        disabled={editMode ? false : true}
                       defaultValue={dob}
                        onChange={(e) => setDob(e.target.value)}
                        type="date"
                        className="registration-datePicker"


                      />
                    </div>
                  </div>





                  <div className="row">
                    <div className="form-group col-12 col-md-6">
                      <label className="text-left">Email</label>
                      <input
                        disabled= "true"
                        defaultValue={email}
                        type="email"
                        className="registration-form-control"
                        onChange={(e)=>{
                          setNewEmail(e.target.name);
                        }}

                      />
                      {/*editMode ? <button className="btn btn-primary p-2 mt-2 ml-auto" type="button" onClick={()=>{
                        resetEmailRequest();
                      }}>Save</button> : null*/}

                      {editMode ?<button type="button" className='d-block mt-1 ml-auto'  title="Update Email" onClick={()=>{resetEmailRequest();}}>
                        <div className="">
                          <span className="mr-lg-2">
                            <i className="fas fa-edit "></i>
                          </span>

                        </div>
                        </button>: null}

                    </div>
                    <div className="form-group col-12 col-md-6">
                      <label className="text-left" for="phone">Phone Number</label>





                      <div className="form-group">

                            <input disabled={editMode ? false : true} type="tel" className="registration-form-control" id="phone" name="phone" placeholder="Enter your phone number"
                                   pattern="[+]?[0-9]{9,15}" required title="Phone should be between 9 and 15 digits and only accept the + sign" value={phone}
                                   onChange={(e) => setPhoneNo(e.target.value)} style={{color:"black"}}/>
                            <div className="invalid-feedback">
                              Please enter a valid phone number (between 9 and 15 digits and only accept the + sign).
                            </div>
                          </div>


                    </div>
                  </div>

                  <div className="row ">
                    <div className="form-group col-md-12">



                      <div className="form-group">
                      <label className="text-left" htmlFor="address">Address</label>

                      <input
                      type="text"
                      className="registration-form-control"
                      id="address"
                      name="address"
                      placeholder="Enter Address"
                      pattern=".{3,200}" required title="Address should be between 3 and 200 characters long"
                      style={{color:"black"}}
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      disabled={editMode ? false : true}
                      />
                        <div className="invalid-feedback">
                          Please enter a valid address (between 3 and 200 characters).
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="form-group col-md-12">
                      <div className="form-group">
                      <label className="text-left" htmlFor="town">Town</label>

                      <input
                      type="text"
                      className="registration-form-control"
                      id="town"
                      name="town"
                      placeholder="Enter Town"
                      pattern=".{3,20}" required title="Address should be between 3 and 20 characters long"
                      style={{color:"black"}}
                      value={town}
                      onChange={(e) => setTown(e.target.value)}
                      disabled={editMode ? false : true}
                      />
                        <div className="invalid-feedback">
                          Please enter a valid address (between 3 and 20 characters).
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="form-group col-md-12">
                      <label className="text-left">Post Code</label>
                      <input
                        disabled={editMode ? false : true}
                        defaultValue={postCode}
                        pattern=".{3,20}" required title="Address should be between 3 and 10 characters long"
                        onChange={(e) => setPostCode(e.target.value)}
                        type="text"
                        className="registration-form-control"
                        required

                      />
                      <div className="invalid-feedback">
                        Please enter a valid address (between 3 and 10 characters).
                      </div>
                    </div>
                  </div>



                  <div
                    className={`row justify-content-end ${
                      editMode ? "d-block" : "d-none"
                    }`}
                  >
                    <div className="col-md-4 ml-auto">
                      <Spin spinning={isLoading}>
                        <button
                          type="Submit"
                          className="registration-btn btn-primary "
                        >
                          Save
                        </button>
                      </Spin>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
               className={editMode?"text-primary":""}
                onClick={() => {
                  setEditMode(!editMode);
                }}
              >
                Edit Profile?
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*<EmailUpdate/>*/}
    </div>
  );
};

export default UserProfileModal;
